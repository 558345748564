.rheostat-horizontal {
    flex: 1;
    height: 1.75rem;
}

.rheostat-horizontal .rheostat-background {
    height: 1rem;
    top: 0.875rem;
    width: calc(100% + 1rem);
}

.rheostat-horizontal .rheostat-progress {
    height: 1rem;
    top: 0.875rem;
}

.rheostat-horizontal .rheostat-handle {
    z-index: 1;
}

.rheostat-horizontal .rheostat-handle:before,
.rheostat-horizontal .rheostat-handle:after {
    top: 0.375rem;
    height: 0.625rem;
    width: 0.0625rem;
}

.rheostat-horizontal .rheostat-handle:before {
    left: 0.5rem;
}

.rheostat-horizontal .rheostat-handle:after {
    left: 0.8125rem;
}
