@import "../variable.scss";

// _OLD_TODO set in styled-components
.valuation-mobile {
    .body {
        .react-selectize.root-node {
            width: 100%;
        }

        .valuation-text-area-editor {
            background-color: #f0f0f0;
        }
        textarea {
            min-height: 200px;
        }
    }

    .SingleDatePicker {
        width: 100%;
        .SingleDatePickerInput {
            .DateInput {
                width: 100%;
                .DateInput_input {
                    height: 39px;
                    margin: 0;
                }
            }
        }
    }

    .money-input {
        @extend [type="text"] !optional;
    }
}

// Dashboard table col styles based on show-for-large class
@media screen and (max-width: 39.9375em) {
    .mobile-inspect-col-image {
        width: 25% !important;
    }

    .mobile-inspect-col-address {
        width: 75% !important;
    }
}
