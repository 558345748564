@import "../../variable.scss";

.rheostat {
    overflow: visible;
}
.rheostat-background {
    max-height: 2px;
    background-color: $slider-background;
    border: 0;
    position: relative;
}

.rheostat-progress {
    background-color: $slider-color;
    max-height: 2px;
    position: absolute;
}

.rheostat-handle {
    background-color: $slider-handler-background-color;
    border: 1px solid white;
    border-radius: 50%;
    height: 24px;
    outline: none;
    z-index: 2;
    width: 24px;
}

.rheostat-handle:before,
.rheostat-handle:after {
    content: "";
    display: block;
    position: absolute;
    background-color: $slider-handler-color;
}
