@import "./src/resources/styles/variables/variables";
@import "./src/resources/styles/mixins/mixins";

.backdrop {
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-color: safe-hsla(map-get($colorBlack, default), 0.5);
    backdrop-filter: blur(0.0625rem);
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 200ms;
    z-index: 50;

    &[state~="is-active"] {
        transition-duration: 250ms;
        transition-delay: 0ms;
        opacity: 1;

        .container {
            opacity: 1;
            transition-delay: 150ms;
            transition-duration: 350ms;
        }
    }

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0;
        margin: 2rem;
        min-width: 30rem;
        min-height: 8rem;
        max-width: calc(100% - 4rem);
        max-height: calc(100% - 4rem);
        opacity: 0;
        border-radius: map-get($borderRadius, small);
        background-color: safe-hsla(map-get($colorWhite, default));
        box-shadow: 0 0.125rem 0.3125rem -0.125rem safe-hsla(map-get($colorBlack, shadow));
        transition: map-get($transition, default);

        @include respond-to("xsmall") {
            margin: 4rem;
            max-width: calc(100% - 8rem);
            max-height: calc(100% - 8rem);
        }

        .closeButtonContainer {
            position: absolute;
            top: -1rem;
            right: -1rem;
            width: 2rem;
            height: 2rem;
            border-radius: map-get($borderRadius, round);
            background-color: safe-hsla(map-get($colorWhite, default));

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                height: 100%;
                color: safe-hsla(map-get($colorWhite, default));
                border: 0.125rem solid safe-hsla(map-get($colorRed, default));
                background-color: safe-hsla(map-get($colorRed, default));
                border-radius: map-get($borderRadius, round);
                transition: map-get($transition, default);

                &:disabled {
                    opacity: map-get($opacity, disabled);
                }

                &:hover:not(:disabled),
                &:focus:not(:disabled) {
                    background-color: safe-hsla(map-get($colorRed, lighter));
                }

                &:active:not(:disabled) {
                    transform: scale(0.9);
                }

                .text {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }

        .unit {
            margin: 1rem 0;
            padding: 0 1rem;
            overflow-y: auto;

            .header {
                position: sticky;
                top: 0;
                padding-bottom: 2rem;
                background: safe-hsla(map-get($colorWhite, default));
                z-index: 5;

                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 1rem;
                    width: 100%;
                    height: 0.0625rem;
                    background-color: safe-hsla(map-get($colorBlue, default));
                }
                .headerContainer {
                    display: flex;
                }
            }

            .footer {
                position: sticky;
                bottom: 0;
                padding-top: 2rem;
                background: safe-hsla(map-get($colorWhite, default));
                z-index: 5;

                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 1rem;
                    width: 100%;
                    height: 0.0625rem;
                    background-color: safe-hsla(map-get($colorBlue, default));
                }
            }

            .content {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
