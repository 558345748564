@import "./src/resources/styles/variables/variables";
@import "./src/resources/styles/mixins/mixins";

.custom-select-container {
    .custom_select {
        &__control {
            border: 0.0625rem solid safe-hsla(map-get($colorGray, lighter));
            cursor: pointer;
            transition: map-get($transition, default);

            svg {
                transition: map-get($transition, default);
            }

            &--is-focused {
                border-color: safe-hsla(map-get($colorGray, lighter));
                box-shadow: none;
            }

            &:hover,
            &:focus {
                border-color: safe-hsla(map-get($colorGray, lighter));

                svg {
                    color: safe-hsla(map-get($colorBlack, lighter));
                    fill: currentColor;
                }
            }
        }

        &__menu-list {
            padding: 0;
        }

        &__menu,
        &__menu-portal {
            position: inherit;
        }

        &__option {
            z-index: 20;
            cursor: pointer;
            word-wrap: break-word;
        }

        &__single-value--is-disabled {
            color: safe-hsla(map-get($colorBlack, lighter));
        }
    }
}
