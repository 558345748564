@import "./src/resources/styles/mixins/mixins";
@import "./src/resources/styles/variables/variables";

@keyframes move-text {
    0% {
        bottom: -0.05em;
        opacity: 1;
    }
    50% {
        bottom: 0.05em;
    }
    100% {
        bottom: 0;
        opacity: 1;
    }
}

.container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: safe-hsla(map-get($colorBlue, default));
    max-height: 100%;

    &[variant~="min-height"] {
        min-height: 2.25rem;
    }

    &[variant~="color-inherit"] {
        color: inherit;
    }

    span {
        position: relative;
        opacity: 0;
        animation: move-text 0.75s infinite;
    }
}
