@import "../variable.scss";

.valuation {
    .category-tab-bar {
        ul {
            list-style: none;
        }

        li {
            float: left;
            padding: 5px;
            text-align: center;
            .fa {
                margin-right: 5px;
            }
        }

        li.active {
            background-color: $primary-color-bright;
        }
    }

    .valuation-header {
        .valuation-header-image {
            img {
                min-height: 140px;
            }
        }

        .valuation-header-information {
            position: relative;
            border-bottom: 1px solid black;

            .valuation-location-container {
                min-height: 140px;
            }

            .category-tab-bar-container {
                position: absolute;
                bottom: 0;
            }
        }
    }

    .valuation-body {
        margin-top: 10px;
    }

    .object-container {
        .object-container-item {
            border: 1px solid black;
            margin-top: 10px;
            padding-left: 10px;
        }
    }
}
