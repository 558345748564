@import "./src/resources/styles/mixins/mixins";
@import "./src/resources/styles/variables/variables";

.container {
    display: flex;
    flex-direction: row;
    width: 100%;

    &[variant~="content-bottom"] {
        flex-direction: column;

        .contentContainer {
            margin-top: 0.25rem;
        }
    }

    &[variant~="content-top"] {
        flex-direction: column-reverse;

        .contentContainer {
            margin-bottom: 0.25rem;
        }
    }

    &[variant~="content-left"] {
        flex-direction: row-reverse;

        .contentContainer {
            margin-right: 0.5rem;
        }
    }

    &[variant~="content-right"] {
        .contentContainer {
            margin-left: 0.5rem;
        }
    }

    .progress {
        appearance: none;
        border: none;
        width: 100%;
        height: 1rem;
        border-radius: map-get($borderRadius, small);
        background-color: safe-hsla(map-get($colorGray, extraLighter));
        color: safe-hsla(map-get($colorGreen, default));

        &::-webkit-progress-bar {
            background-color: safe-hsla(map-get($colorGray, extraLighter));
            border-radius: map-get($borderRadius, small);
        }

        &::-webkit-progress-value {
            background-color: safe-hsla(map-get($colorGreen, default));
            border-radius: map-get($borderRadius, small);
            transition: map-get($transition, default);
        }

        &::-moz-progress-bar {
            background-color: safe-hsla(map-get($colorGreen, default));
            border-radius: map-get($borderRadius, small);
            transition: map-get($transition, default);
        }
    }

    .contentContainer {
        display: flex;
        flex-direction: row;
        gap: 0.25rem;

        .contentUnit {
            font-size: 0.75rem;

            &[variant~="before"] {
                font-weight: bolder;
                color: safe-hsla(map-get($colorRed, default));

                &:after {
                    content: " - ";
                    font-weight: normal;
                    color: safe-hsla(map-get($colorBlack, default));
                    margin-left: 0.125rem;
                }
            }
        }
    }
}
