.legacy-bar {
    z-index: 810;

    background-color: #80000e;
    color: white;
    padding: 5px;

    position: fixed;
    height: 35px;
    bottom: 0;
    left: 0;
    width: 100%;
}

ul.legacy-bar-list {
    border: 1px solid black;
    position: absolute;
    bottom: 35px;
    left: 0;
    z-index: 10;
    margin: 0;

    background-color: white;
    min-width: 300px;

    list-style: none;

    li {
        text-transform: capitalize;
        padding: 5px;
        margin: 5px;
    }

    li.open {
        background-color: lightgrey;
    }

    li.closed {
        background-color: darkgrey;
    }

    li.header {
        font-style: normal;
        font-weight: bold;
        border-bottom: 1px solid black;
    }
}
