@import "./src/resources/styles/mixins/mixins";
@import "./src/resources/styles/variables/variables";

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: map-get($gap, default);
    color: safe-hsla(map-get($colorBlack, lighter));

    &[variant~="disabled"],
    &[variant~="read-only"] {
        opacity: map-get($opacity, disabled);
    }

    .titleContainer {
        display: flex;
    }

    .label {
        display: flex;
        flex: 1;
        line-height: 2rem;
        margin: 0;
        padding: 0 0.5rem;
        user-select: none;
        color: safe-hsla(map-get($colorWhite, default));
        cursor: pointer;
        overflow: hidden;

        svg {
            path,
            line {
                stroke: currentColor;
            }
        }
    }

    .output {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 0.825rem;
        text-align: center;
        z-index: 5;
    }

    .inputContainer {
        position: relative;
        display: flex;
        flex: auto;
        background: safe-hsla(map-get($colorWhite, default));
        border: 0.125rem solid safe-hsla(map-get($colorWhite, default));
        border-radius: map-get($borderRadius, small);

        .input {
            position: relative;
            font-size: 1rem;
            line-height: 1;
            width: 100%;
            color: safe-hsla(map-get($colorBlack, lighter));
            border: 0;
            background: transparent;
            opacity: map-get($opacity, show);
            padding: calc(map-get($gap, default) / 2);
            z-index: 10;
            caret-color: safe-hsla(map-get($colorBlack, default));

            &[type="number"] {
                -moz-appearance: textfield;
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            @include respond-to("medium") {
                font-size: 1rem;
            }

            &::placeholder {
                color: inherit;
                opacity: map-get($opacity, show);
            }

            &:focus {
                outline: 0;
                &::placeholder {
                    opacity: map-get($opacity, hidden);
                }
            }

            &:not(output):-moz-ui-invalid {
                box-shadow: transparent 0 0 0 0;
            }

            &:disabled,
            &[readonly] {
                cursor: default;
                pointer-events: none;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                transition-delay: 9999s;
                -webkit-transition-delay: 99999s;
            }
        }
    }

    .output + .input {
        user-select: none;
        color: transparent;
    }

    &[state="isFocussed"] {
        &::after {
            height: 0.125rem;
            z-index: 5;
        }

        .output {
            opacity: 0;
        }
    }

    .border {
        position: absolute;
        max-width: 100%;
        height: 0.0625rem;
        background: map-get($colorRed, default);
        bottom: 0;
        left: 0;
        z-index: 10;
    }

    &[variant~="color-gray"] {
        color: safe-hsla(map-get($colorWhite, default));

        .output {
            color: safe-hsla(map-get($colorWhite, default));
        }

        .inputContainer {
            background: safe-hsla(map-get($colorGray, darken));
            border-color: safe-hsla(map-get($colorGray, darken));
        }

        &[state="isFocussed"] {
            .inputContainer {
                .input {
                    color: safe-hsla(map-get($colorWhite, default));
                }
            }
        }
    }

    &[variant~="small"] {
        .inputContainer {
            .input {
                font-size: 0.825rem;
                padding: calc(map-get($gap, default) / 2);
                text-align: center;
                caret-color: safe-hsla(map-get($colorWhite, default));
            }
        }
    }
}
