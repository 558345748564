.react-tiny-popover-container {
    overflow: visible !important;
    background: white;
    min-width: 350px;
    margin-top: -50px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    > .arrow {
        border-width: 10px;
        left: 50%;
        &,
        &:after {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            margin-left: -11px;
            bottom: -20px;
            border-top-color: rgba(0, 0, 0, 0.25);
        }

        &:after {
            content: "";
            bottom: -11px;
            border-width: 11px;
            border-top-color: #fff;
        }
    }

    .popover-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        span {
            color: #005b86;
            font-size: 14px;
            background-color: #f7f7f7;
            font-weight: bold;
        }
        i {
            float: right;
            font-size: 18px;
            font-weight: 700;
            line-height: 1;
            color: #000;
            text-shadow: 0 1px 0 #fff;
            filter: alpha(opacity=20);
            opacity: 0.2;
            cursor: pointer;
        }
    }

    .popover-body {
        padding: 9px 14px;
        color: #005b86;
        font-size: 14px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

.Popover {
    z-index: 25;
}
