@import "./src/resources/styles/variables/variables";
@import "./src/resources/styles/mixins/mixins";

.dashboard {
    @import "card.scss";
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: normal;

    .react-grid-item {
        touch-action: initial;

        &[variant~="overlapped"] {
            z-index: 0;
        }

        &[variant~="overlapping"] {
            z-index: 1;
        }
    }

    .dashboard-body {
        .react-grid-layout {
            width: 100%;
        }
    }
}

.dashboard.mobile-inspect-activated > .dashboard-header {
    // Hide on mobile, its not mobile friendly
    display: none;
}

.pointer {
    cursor: pointer;
}

.pagination li:hover {
    background: none;
}
