.customMoneyType {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 2.4375rem;
    margin: 0 0 1rem;
    padding: 0.5rem;
    border: 1px solid #cacaca;
    border-radius: 0;
    background-color: #fefefe;
    box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, 0.1);
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0a0a0a;
    transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
    appearance: none;
}

.hidden-xs {
    @media screen and (max-width: 39.9375em) {
        display: none;
    }
}

.app-version-number {
    z-index: 99999;
    background-color: lightslategray;
    color: white;
    padding: 5px;

    position: fixed;
    height: 35px;
    bottom: 0;
    right: 0;
}

.axios-queue-item {
    z-index: 800;

    background-color: orange;
    color: white;
    padding: 5px;

    position: fixed;
    width: 100vw;
    height: 35px;
    bottom: 0;
    left: 0;
}

.card-divider {
    background-color: #005b86;
    color: white;
    h5 {
        font-weight: bold;
        padding: 5px;
        font-size: 14px;
    }
}

.react-datepicker-popper {
    z-index: 9999;
}

.Popover,
.popover {
    &.Popover-above {
        z-index: 25;
    }

    .popover-header {
        color: #005b86;
        padding: 0.75rem;
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: #f7f7f7;
        border-bottom: 1px solid #ebebeb;
        border-radius: 5px 5px 0 0;

        i {
            float: right;
            margin-top: 2px;
            cursor: pointer;
        }
    }

    .popover-body {
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        color: #005b86;
        font-size: 14px;
        min-width: 250px;
        max-height: 16rem;
        overflow: hidden;
        overflow-y: auto;
    }

    & > .Popover-tip {
        fill: rgba(0, 0, 0, 0.2);
    }
}

.capitalize {
    text-transform: capitalize;
}

.w-100 {
    width: 100%;
}
