@import "./src/resources/styles/mixins/mixins";
@import "./src/resources/styles/variables/variables";

@mixin variants {
    &[variant~="smallest"] {
        width: 0.75rem;
        height: 0.75rem;
    }
    &[variant~="small"] {
        width: 1rem;
        height: 1rem;
    }
    &[variant~="normal"] {
        width: 1.5rem;
        height: 1.5rem;
    }
    &[variant~="large"] {
        width: 2rem;
        height: 2rem;
    }
    &[variant~="largest"] {
        width: 4rem;
        height: 4rem;
    }
    &[variant~="full-width"] {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
    }
}

.container {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;

    @include variants();
}

.icon {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    fill: currentColor;

    @include variants();
}
