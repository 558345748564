$window-width: 100vw;
$window-height: 100vh;
$grid-columns: 48;

// THEME COLOR
$primary-color: #262d33;
$primary-color-dark: #414449;
$primary-color-bright: lighten($primary-color-dark, 50%);
$primary-attention-color: #ff2d7a;

$primary-background-color-bright: white;
$primary-background-color-dark: #3b444d;
$primary-background-color: #2c506d;

$primary-text-color: #2c506d;
$primary-text-color-bright: white;
$primary-text-color-dark: darkgrey;
$primary-border-color: darkgrey;

$logo-letter-spacing: 5px;
$logo-font-size: 13px;
$logo-font-weight: 300;
$logo-font-family: "Roboto", sans-serif;

// THEME FONT
$font-family: "Roboto", sans-serif;
$font-small: 11px;
$font-medium: 13px;
$font-large: 15px;
$font-extra-small: 6px;
$font-extra-large: 22px;

$font-thin-weight: 300;
$font-normal-weight: 400;
$font-bold-weight: 600;

// BASE COLORS
$red: red;
$green: green;
$blue: blue;

// SLIDER SETTINGS
$slider-background: rgba(0, 0, 0, 0.3);
$slider-color: red;
$slider-handler-background-color: $primary-color-dark;
$slider-handler-color: #dadfe8;

// MENU
$menu-bar-height: 60px;

// slider
$slider-value-background-color: rgba(0, 0, 0, 0.2);
// select
$select-dropdown-background-color: #545559;
$select-option-highlight: #8c8c8c;
$select-option-background: transparent;
// form font
$filter-form-font-size-category: 15px;
// search
$search-container-height: 60px;
$search-container-background-color: $primary-background-color-bright;
$search-container-text-color: #75818c;
$search-background-color: #dee4e9;
$search-bar-icon-color: #82888e;
$search-icon-color: #8295a8;
$search-icon-active-color: #ff8889;
// table container
$table-background-color: #c3c9c5;

// map
$map-circle-radius-color: #ffa637;
$map-circle-radius-font-size: 30px;

//valuation selector
$valuation-selector-height: $search-container-height;
$valuation-selector-background-color: $primary-background-color;
$valuation-selector-color: white;

//valuation::select-widget
$button-not-selected-background: whitesmoke;
$button-selected-background: #50af31;

$table-striped-background: rgb(243, 246, 251);
$table-head-font-color: rgb(194, 194, 194);
