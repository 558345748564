.error-page {
    background-color: #323840;
    width: 100vw;
    height: 100vh;

    .error-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .error-box {
        position: absolute;
        width: 650px;
        left: 35vw;

        height: auto;
        top: 25vh;

        color: white;

        .small {
            font-size: 15px;
            margin-bottom: 15px;

            a {
                text-decoration: underline;
                color: white;
            }
        }

        p {
            color: white;
        }
    }
}

.no-ie-error-box {
    width: calc(100% - 200px);
    min-width: 300px;
    margin: 0 auto;
}
