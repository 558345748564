@import "./src/resources/styles/variables/variables";
@import "./src/resources/styles/mixins/mixins";

.card {
    .header {
        position: sticky;
        top: 0;
        left: 0;
        min-height: 3rem;
        z-index: 5;
        flex: none;
    }

    .card-section {
        thead th,
        thead td,
        tfoot th,
        tfoot td {
            white-space: nowrap;
        }
    }
}
