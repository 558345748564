@import "./src/resources/styles/variables/variables";
@import "./src/resources/styles/mixins/mixins";

.datatable {
    height: 100%;
    width: 100%;

    &[variant~="scroll"] {
        height: 81vh;
        width: 100%;
        padding: 0 0 1rem 0;
    }

    .action-dropdown {
        margin-right: 1rem;

        .dropdown-item:active {
            background: transparent;
            color: unset;
        }
        .btn {
            font-size: 0.875rem;
            background-color: transparent;
            border: 0;

            &:focus {
                background: #545b62;
                outline: none;
            }
        }

        button:enabled {
            .post-text {
                color: #337ab7;
            }
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        overflow: hidden;
        overflow-x: auto;

        &[variant~="scroll"] {
            overflow: auto;
        }
    }

    .table {
        .table-header {
            height: 40px;
            border-bottom: 2px solid #dddddd;
            margin-bottom: 5px;
            width: 100%;
            background: #f8f8f8;
            color: #c2c2c2;
            margin: -1px;

            &[variant~="narrow"] {
                height: 0;
            }

            &[variant~="scroll"] {
                position: sticky;
                top: 0;
                z-index: 10;
            }

            .th-reset-input {
                padding: 0 0 0 0.75rem;
                vertical-align: middle;
            }
        }

        .table-header-column {
            margin: 0;
            padding: 0.5rem 0.15rem;
            text-transform: capitalize;

            &[variant~="narrow"] {
                padding: 0.15rem;
            }

        }

        .table-header-filter {
            div {
                padding-top: 5px;
                height: 100%;
                font-weight: 700;
                color: rgb(194, 194, 194);
            }

            select {
                font-size: 0.75rem;
                max-width: 100%;
                * {
                    color: black;
                }
            }
            border-bottom: 2px solid #dddddd;
            width: 100%;

            td {
                &[variant~="filter"] {
                    width: fit-content;
                }
            }
        }

        .table-body-row.even:hover {
            background-color: darken(#f3f6fb, 5%);
        }

        .table-body-row.odd:hover {
            background-color: darken(white, 5%);
        }

        .table-body-row.even {
            background-color: #f3f6fb;
        }

        .table-body-row.odd {
            background-color: white;
        }

        .loading-data-row {
            width: 100%;
            text-align: center;
        }

        .table-body-row {
            color: #005b86;
            width: 100%;
            border-bottom: 0.5px solid #dddddd;
            overflow: hidden;

            &[variant~="transaction"] {
                cursor: pointer;
            }

            .table-body-row-column {
                margin: 0;
                padding: 0.5rem 0;
                vertical-align: top;
                position: relative;
                overflow: hidden;

                &[variant~="narrow"] {
                    padding: 0;
                    vertical-align: inherit;
                }
            }

        }

        .table-body-row:last-child {
            border-bottom: 0;
        }

        .circle-image {
            padding: 0;
            background-size: cover;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            margin-top: 5px;
        }
    }

    .card-divider.header {
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        z-index: 10;

        .header-button {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            min-height: 3rem;
            justify-content: flex-end;

            &[variant~="narrow"] {
                min-height: 1rem;
            }

            @include media-breakpoint-up(sm) {
                justify-content: center;
                flex-wrap: wrap;
            }

            .v-separator {
                width: 1px;
                background: white;
                height: 1rem;
            }

            .item {
                display: flex;

                @include media-breakpoint-up(sm) {
                    margin-right: 1rem;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                &__hidden {
                    display: none;
                }

                &.drag-element {
                    cursor: grab;
                    &:active {
                        cursor: grabbing;
                    }
                }

                .item:last-child {
                    margin-right: 0;
                }

                .post-text {
                    padding-left: 0.5rem;
                }
            }
        }
    }

    .header-button {
        .item {
            cursor: pointer;
        }

        .hover-button {
            padding-left: 0.5rem;

            &:hover {
                opacity: 0.8;
            }
        }

        .table-search-input {
            display: flex;
            align-items: center;
            height: 1.5rem;
            flex-wrap: nowrap;
            font-size: 1rem;
            margin-bottom: 0;

            @include media-breakpoint-up(sm) {
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }

            .input-group-field {
                background-color: transparent;
                border: 0;
                border-bottom: 1px solid white;
                margin: 0;
                border-radius: 0;
                flex: 1 1;
                height: auto;
                font-size: 0.875rem;
            }

            *:focus {
                outline: none;
                box-shadow: none;
            }

            input {
                box-shadow: none;
                caret-color: white;
                color: white;
                padding: 0;
            }

            .input-group-label {
                background-color: transparent;
                caret-color: white;
                color: white;
                border: 0;
                padding: 0 1rem 0 0;
            }
        }
    }

    .sum {
        font-size: 0.875rem;
        padding: 0.75rem 1rem;
        background: #f5f5f5;
        color: #404040;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .footer {
        position: sticky;
        left: 0;
        bottom: 0;
        border-top: 1px solid #005b86;
        background-color: white;
        margin-top: auto;
        width: 100%;
        flex-grow: 0;
        padding: 0;

        .pagination {
            bottom: 1rem;
            right: 1rem;
            margin: 0;
            padding: 0.75rem 1rem;

            li {
                outline: none;
                display: inline-block;
                margin: 0;
                padding: 0;

                &:first-child {
                    border-top-left-radius: 0.5rem;
                    border-bottom-left-radius: 0.5rem;
                }
                &:last-child {
                    border-top-right-radius: 0.5rem;
                    border-bottom-right-radius: 0.5rem;
                }

                &:first-child,
                &:last-child {
                    a {
                        &:hover,
                        &:focus {
                            color: safe-hsla(map-get($colorBlue, default), map-get($opacity, small));
                            background-color: safe-hsla(map-get($colorWhite, default));
                        }
                    }

                    &.disabled {
                        a {
                            &:hover,
                            &:focus {
                                color: safe-hsla(map-get($colorGray, default), map-get($opacity, disabled));
                                background-color: safe-hsla(map-get($colorWhite, default));
                            }
                        }
                    }
                }

                &.break {
                    padding: 0;
                    margin-top: -1rem;
                }

                &.disabled {
                    a {
                        cursor: default;
                        color: safe-hsla(map-get($colorGray, default), map-get($opacity, disabled));

                        &:hover,
                        &:focus {
                            background-color: safe-hsla(map-get($colorWhite, default));
                        }
                    }
                }

                &.current {
                    a {
                        cursor: default;
                        color: safe-hsla(map-get($colorWhite, default));
                        background-color: safe-hsla(map-get($colorBlue, default));

                        &:hover,
                        &:focus {
                            background-color: safe-hsla(map-get($colorBlue, default));
                        }
                    }
                }

                a {
                    color: safe-hsla(map-get($colorBlue, default));
                    background-color: safe-hsla(map-get($colorWhite, default));
                    transition: map-get($transition, default);

                    &:hover,
                    &:focus {
                        outline: none;
                        background-color: safe-hsla(map-get($colorGray, extraLighter));
                    }
                }
            }
        }
    }
}
.table {
    margin-bottom: 0;

    &-header {
        &-column {
            margin: 0;
            padding: 0;
        }
    }
}
