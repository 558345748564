.select-environment {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    position: relative;
    top: 0;
    left: 0;

    background: linear-gradient(rgba(0, 91, 134, 0.85), rgba(0, 44, 63, 0.85)), url("./images/login-bg.jpg");
    background-size: cover;

    .options {
        position: absolute;
        top: calc(50vh - 200px);
        height: 400px;
    }

    .fa-stack {
        clear: both;
    }
    .text {
        color: white;
        clear: both;
    }
}
